
body {
  margin: 0px;
  padding: 0px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(to top, #dad4ec 0%, #dad4ec 1%, #f3e7e9 100%);
  /* background-image: linear-gradient(135deg, #fdfcfb 0%, #e2d1c3 100%);
  background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%); */
  
  background-repeat: no-repeat;
  background-attachment: fixed;
}




  
  #style-1::-webkit-scrollbar {
    width: 7px;
  }
  
  /* Track */
  #style-1::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 5px;
  }
   
  /* Handle */
  #style-1::-webkit-scrollbar-thumb {
    background: rgb(170, 170, 170); 
    border-radius: 5px;
  }
  
  /* Handle on hover */
  #style-1::-webkit-scrollbar-thumb:hover {
    background: rgb(136, 136, 136); 
  }







@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    page-break-before: always;
  }
}

@page {
  size: auto;
  margin: 15mm;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto;
}